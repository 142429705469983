import { useMemo, useState } from 'react'
import { Trade, TradeType } from '@gizzard-dex/sdk'
import { Button, Text, ErrorIcon, ArrowDownIcon, AutoRenewIcon, AirportIcon, Flex } from 'packages/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from 'utils/prices'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from 'utils/truncateHash'
import QuestionHelper from 'components/QuestionHelper'
import styled from 'styled-components'
import { TruncatedText, SwapShowAcceptChanges, StyledBalanceMaxMini } from './styleds'
import FormattedPriceImpact from './FormattedPriceImpact'

const SwapModalFooterContainer = styled(AutoColumn)`
  background-color: transparent;
  gap: 8px;
`

const ModalSwap = styled(AutoColumn)`
  padding: 0;
`

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const { t } = useTranslation()
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage],
  )
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const [showInverted, setShowInverted] = useState<boolean>(false)

  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <>
      <ModalSwap gap="md">
        <Flex width="100%" justifyContent="space-between" style={{ gap: '8px' }} mb="12px">
          <Flex
            flexDirection="column"
            background="#d9d9d9"
            borderRadius="10px"
            padding={['20px']}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <RowFixed gap="0px">
              <CurrencyLogo currency={trade.inputAmount.currency} size="30px" />
              <Text fontSize={['14px', , , , '24px']} ml="10px">
                {trade.inputAmount.currency.symbol}
              </Text>
            </RowFixed>

            <RowFixed gap="0px">
              <TruncatedText
              // color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? 'pink' : 'black'}
              >
                {trade.inputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
          </Flex>

          <RowFixed>
            <Flex background="black" borderRadius="50%" width="26px" height="26px">
              <AirportIcon width="18px" ml="4px" />
            </Flex>
          </RowFixed>

          <Flex
            flexDirection="column"
            background="#d9d9d9"
            borderRadius="10px"
            padding={['20px']}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <RowFixed gap="0px">
              <CurrencyLogo currency={trade.outputAmount.currency} size="30px" />
              <Text fontSize={['14px', , , , '24px']} ml="10px">
                {trade.outputAmount.currency.symbol}
              </Text>
            </RowFixed>

            <RowFixed gap="0px">
              <TruncatedText
              // color={
              //   priceImpactSeverity > 2
              //     ? 'failure'
              //     : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
              //     ? 'pink'
              //     : 'black'
              // }
              >
                {trade.outputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
          </Flex>
        </Flex>

        {showAcceptChanges ? (
          <SwapShowAcceptChanges justify="flex-start" gap="0px">
            <RowBetween>
              <RowFixed>
                <ErrorIcon mr="8px" />
                <Text bold fontSize={['12px', , , , '16px']}>
                  {' '}
                  {t('Price Updated')}
                </Text>
              </RowFixed>
              <Button variant="primary" onClick={onAcceptChanges}>
                <Text bold fontSize={['12px', , , , '16px']}>
                  {t('Accept')}
                </Text>
              </Button>
            </RowBetween>
          </SwapShowAcceptChanges>
        ) : null}

        <Flex width="100%" height="2px" background="rgba(155, 155, 155, 1)" />

        <SwapModalFooterContainer>
          <RowBetween align="center">
            <Text bold fontSize={['12px', , , , '16px']}>
              {t('Price')}
            </Text>
            <Text
              color="black"
              fontSize={['12px', , , , '16px']}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                textAlign: 'right',
                paddingLeft: '10px',
              }}
            >
              {formatExecutionPrice(trade, showInverted)}
              {/* <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
                <AirportIcon style={{ rotate: '90deg' }} width="14px" color="text" />
              </StyledBalanceMaxMini> */}
            </Text>
          </RowBetween>

          <RowBetween>
            <RowFixed>
              <Text fontSize={['12px', , , , '16px']} bold>
                {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}
              </Text>
              <QuestionHelper
                text={t(
                  'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
                )}
                ml="4px"
              />
            </RowFixed>
            <RowFixed>
              <Text fontSize={['12px', , , , '16px']} bold>
                {trade.tradeType === TradeType.EXACT_INPUT
                  ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                  : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
              </Text>
              <Text fontSize={['12px', , , , '16px']} ml="4px">
                {trade.tradeType === TradeType.EXACT_INPUT
                  ? trade.outputAmount.currency.symbol
                  : trade.inputAmount.currency.symbol}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <Text fontSize={['12px', , , , '16px']} bold>
                {t('Price Impact')}
              </Text>
              <QuestionHelper
                text={t('The difference between the market price and your price due to trade size.')}
                ml="4px"
              />
            </RowFixed>
            <FormattedPriceImpact color="text" priceImpact={priceImpactWithoutFee} />
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <Text fontSize={['12px', , , , '16px']} bold>
                {t('Liquidity Provider Fee')}
              </Text>
              <QuestionHelper
                text={
                  <>
                    <Text mb="12px" fontSize={['12px', , , , '14px']}>
                      {t('For each trade a %amount% fee is paid', { amount: '0.25%' })}
                    </Text>
                    <Text fontSize={['12px', , , , '14px']}>
                      - {t('%amount% to LP token holders', { amount: '0.17%' })}
                    </Text>
                    <Text fontSize={['12px', , , , '14px']}>
                      - {t('%amount% to the Treasury', { amount: '0.03%' })}
                    </Text>
                    <Text fontSize={['12px', , , , '14px']}>
                      - {t('%amount% towards Gizzard buyback and burn', { amount: '0.05%' })}
                    </Text>
                  </>
                }
                ml="4px"
              />
            </RowFixed>
            <Text fontSize={['12px', , , , '16px']} bold>
              {realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}
            </Text>
          </RowBetween>
        </SwapModalFooterContainer>

        {recipient !== null ? (
          <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
            <Text color="textSubtle" fontSize={['12px', , , , '16px']}>
              {recipientSentToText}
              <b title={recipient}>{truncatedRecipient}</b>
              {postSentToText}
            </Text>
          </AutoColumn>
        ) : null}
      </ModalSwap>

      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '10px 0' }}>
        {/* <Text fontSize={['14px', , , , '16px']} textAlign="left" style={{ width: '100%' }} lineHeight="2">
          {estimatedText}
          <b>
            {amount} {symbol}
          </b>
          {transactionRevertText}
        </Text> */}
      </AutoColumn>
    </>
  )
}
