import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
      <path
        d="M3.10238 7.2062L2.29241 6.39623C2.1068 6.17688 2.01039 5.8958 2.02234 5.60868C2.03425 5.3216 2.15366 5.04947 2.35684 4.84629C2.56002 4.6431 2.83215 4.52371 3.11924 4.51178C3.40634 4.49984 3.68743 4.59625 3.90677 4.78187L5.19155 6.06665L6.84501 6.06665L5.97359 2.40224C5.90693 2.11971 5.95002 1.82241 6.09415 1.57044C6.23829 1.31846 6.47272 1.1306 6.75004 1.04484C6.89623 0.98323 7.05349 0.952217 7.21211 0.953731C7.37074 0.955233 7.52738 0.989224 7.67237 1.05361C7.81735 1.11799 7.9476 1.21138 8.0551 1.32805C8.1626 1.44471 8.24505 1.58216 8.29737 1.73192L9.88937 6.06107L12.7662 6.04431C13.0743 6.0443 13.3698 6.16671 13.5877 6.38462C13.8057 6.60253 13.9281 6.89806 13.9281 7.2062C13.928 7.51434 13.8057 7.80989 13.5878 8.02779C13.3699 8.24568 13.0743 8.36807 12.7662 8.36809L9.88938 8.35133L8.26944 12.7084C8.21497 12.8529 8.13196 12.9851 8.02538 13.0969C7.91891 13.2088 7.791 13.2981 7.64922 13.3596C7.50756 13.421 7.35495 13.4534 7.2005 13.4548C7.04599 13.4562 6.89282 13.4266 6.75004 13.3676C6.4727 13.2818 6.23831 13.0939 6.09419 12.842C5.95002 12.59 5.90695 12.2926 5.97359 12.0102L6.84501 8.34574L5.19155 8.34574L3.90677 9.63052C3.68742 9.81616 3.40632 9.91257 3.11923 9.90064C2.83213 9.8887 2.56001 9.7693 2.35682 9.56612C2.15364 9.36294 2.03424 9.09081 2.02231 8.80372C2.01038 8.51662 2.10678 8.23552 2.29241 8.01617L3.10238 7.2062Z"
        stroke="red"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
