import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
      <g clipPath="url(#clip0_3347_5702)">
        <path d="M10.7969 15V3L13.4969 5.7" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.20312 3V15L4.50313 12.3" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3347_5702">
          <rect width="11.8794" height="11.8794" fill="white" transform="translate(9 0.599609) rotate(45)" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
