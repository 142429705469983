import { Price } from '@gizzard-dex/sdk'
import { Text, AutoRenewIcon, Flex } from 'packages/uikit'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = showInverted ? price?.invert()?.toSignificant(6) : price?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted ? `1 ${price?.quoteCurrency?.symbol} = ` : `1 ${price?.baseCurrency?.symbol} = `

  const labelEnd = showInverted ? `${price?.baseCurrency?.symbol} ` : `${price?.quoteCurrency?.symbol}`

  return (
    <Text height="100%" textAlign="center" fontSize={['14px', , , , '16px']}>
      {show ? (
        <Flex alignItems="center" justifyContent="flex-start" height="100%">
          {label} {formattedPrice ?? '-'} {labelEnd}
          {/* <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <AutoRenewIcon width="14px" />
          </StyledBalanceMaxMini> */}
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center" height="100%">
          -
        </Flex>
      )}
    </Text>
  )
}
