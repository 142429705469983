import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 31">
      <mask
        id="mask0_1708_4116"
        style={{ maskType: 'luminance' }}
        width="22"
        height="31"
        x="-1"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M-.004 0h20.37v31H-.004V0z" />
      </mask>
      <g mask="url(#mask0_1708_4116)">
        <path
          fill="#000"
          d="M8.382 28.34v.886H7.184v-.885h1.198zm2.395 0v-1.77H9.58v2.656H8.382v.886H9.58v.886h1.197v-.886h1.199v-.886h-1.199v-.885zm1.199.886h1.198v-.885h-1.198v.885zm-5.99-1.77v.885h1.198v-.886H5.985zm7.188.885h1.197v-.886h-1.197v.886zM4.788 26.57v.885h1.197v-.885H4.788zm10.78 0h-1.197v.885h1.198v-.885zM3.59 25.684v.886h1.199v-.886H3.589zm4.793 0v.886H9.58v-.886H8.382zm2.395.886h1.199v-.886h-1.199v.886zm4.792 0h1.199v-.886h-1.2v.886zM2.39 24.798v.886h1.2v-.886h-1.2zm4.793 0v.886h1.198v-.886H7.184zm4.792.886h1.198v-.886h-1.198v.886zm4.792 0h1.197v-.886h-1.197v.886zM1.194 23.913v.885H2.39v-.885H1.194zm4.793 0v.885h1.197v-.885H5.987zm7.187.885h1.197v-.885h-1.197v.885zm4.792 0h1.198v-.885h-1.198v.885zM4.788 23.026v.887h1.199v-.887h-1.2zm9.583.887h1.199v-.887h-1.199v.887zM1.194 22.142v-.886h1.199v-.886h-1.2v-1.772H-.003v5.315h1.198v-1.771zm2.396 0v.884h1.198v-.884H3.59zm11.98.884h1.198v-.884H15.57v.884zm-13.178-1.77v.886H3.59v-.886H2.393zm14.376.886h1.198v-.886h-1.198v.886zm2.396-1.772h-1.198v.886h1.198v2.657h1.198v-5.315h-1.198v1.772zM3.59 18.598h2.397v-.885H1.194v.885H3.59zm11.98 0h3.594v-.885h-4.793v.885h1.199zm-8.386-1.77V.885H5.987v16.827h1.197v-.886zm5.99-14.17v15.055h1.197V.886h-1.197v1.772zM9.58.886h3.594V0h-5.99v.886H9.58z"
        />
      </g>
    </Svg>
  )
}

export default Icon
